import styled from "styled-components";

interface ImagePreviewProps {
  backgroundImage: string;
}

export const WrapperUploadImage = styled.div`
  width: 100%;
  height: 262px;
  display: inline-block;
  border-radius: 10px;
`;

export const WrapperUploadingImage = styled(WrapperUploadImage)`
  background-color: ${(props) => props.theme.colors.DarkBlue};

  height: 262px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;

  button {
    width: 100%;
    height: 24px;
    padding: 0 10px;
  }
`;

export const UploadingTitleImage = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.Roboto};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.colors.white};

  margin: 0;
`;

export const UploadingFileNameImage = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.Roboto};
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  color: ${(props) => props.theme.colors.white};

  margin: 0;

  width: 100%;

  margin-bottom: 10px;
`;

export const UploadingLoadingImage = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.Roboto};
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.white};

  margin: 0;
`;

export const TitleUploadImage = styled.p`
  background-color: ${(props) => props.theme.colors.DarkBlue};

  font-family: ${({ theme }) => theme.fontFamily.Roboto};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  line-height: 26px;

  margin: 0;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ImageWrapperDocument = styled.div`
  background-color: ${(props) => props.theme.colors.Grey30};

  height: 97px;
  padding: 20px 36px;
  width: 100%;
`;

export const ImagePreviewtUpload = styled.div<ImagePreviewProps>`
  width: 100%;
  height: 152px;
  position: relative;

  ${(props) =>
    props.backgroundImage &&
    `
      background-image: url(${props.backgroundImage});
      background-color: ${props.theme.colors.white};
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      ;
    `}

  border-left: 1.00314px solid ${(props) => props.theme.colors.Platinum};
  border-right: 1.00314px solid ${(props) => props.theme.colors.Platinum};
`;

export const ImageUpload = styled.img`
  display: block;

  margin: 0 auto;
  height: 100%;
  width: 100%;
`;

export const TextBoldUploadImage = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.Default};
  color: ${(props) => props.theme.colors.black};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;

  text-align: center;
  white-space: pre-line;

  margin: 14px 0 5px;
`;

export const TextNormalUploadImage = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.Default};
  color: ${(props) => props.theme.colors.black};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  text-align: center;

  margin: 10px 0;
`;


export const ButtonWrapperUploadImage = styled.div`
  padding: 5px 16px 15px;

  border: 1.00314px solid ${(props) => props.theme.colors.Platinum};
  border-top: 0;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  button {
    background-color: ${(props) => props.theme.colors.ManheimYellow};

    font-family: ${({ theme }) => theme.fontFamily.Default};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;

    text-transform: none;

    padding: 0 10px;
  }
`;

export const ButtonWrapperPreviewUploadImage = styled.div`
  padding: 5px 15px 10px;

  border: 1.00314px solid ${(props) => props.theme.colors.Platinum};
  border-top: 0;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  button {
    border: 1px solid ${(props) => props.theme.colors.DarkBlue};
    color: ${(props) => props.theme.colors.DarkBlue};

    width: 100%;
    margin: 5px 14px;

    height: 24px;
    padding: 0 10px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
`;

export const FileInputUploadImage = styled.input`
  position: absolute;
  top: 0px;
  left: 0;

  z-index: 2;

  height: 100%;
  width: 100%;

  opacity: 0;

  cursor: pointer;
`;

export const SubWrapperUploadImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;

  button {
    width: 100%;
    margin-top: 20px;
  }
`;
