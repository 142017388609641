import React, { useEffect } from "react";
import { MaganeStockZeroKmBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsZeroKmEnum } from "../../../../models";
import { CheckInZeroKmOrganism } from "../../../organisms";

export const CheckInZeroKmPage: React.FC = () => {
  const {
    setMenuSelectedStepZeroKmContext,
    menuCurrentStepZeroKmContext,
    menuSelectedStepZeroKmContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepZeroKmContext(
      RenaveMenuStepsZeroKmEnum.CheckInVehicleZeroKm
    );
  }, [setMenuSelectedStepZeroKmContext]);

  return (
    <MaganeStockZeroKmBasePage>
      <CheckInZeroKmOrganism
        viewMode={
          !!menuSelectedStepZeroKmContext &&
          menuSelectedStepZeroKmContext < menuCurrentStepZeroKmContext
        }
      />
    </MaganeStockZeroKmBasePage>
  );
};
