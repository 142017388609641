import {
  Combo,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
  VehicleSearchOptions,
  VehicleSearchOptionsProps,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useKbb, useRenaveContext } from "../../../hooks";

interface IProps {
  vehicleKbbId?: string;
  vehicleMolicarId?: string;
  molicarID?: string;
  className?: string;
  isZeroKm?: boolean;
  sourcePartner: SourcePartnerEnum;
  setSourcePartner: (sourcePartner: SourcePartnerEnum) => void;
  setHasSelectedCategory: (value: boolean) => void;
}

export const StaticVehicleOptions: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => {
    setProps(parameters);
  }, [parameters]);

  const {
    kbbVehicleContext,
    setKbbVehicleContext,
    vehicleCategoryContext,
    setVehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useRenaveContext();

  const {
    GetAllVehicleBrand,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetVehicle,
  } = useKbb();

  const [vehicleBrandCombo, setVehicleBrandCombo] = useState<Combo[]>();
  const [vehicleModelCombo, setVehicleModelCombo] = useState<Combo[]>();
  const [vehicleVersionCombo, setVehicleVersionCombo] = useState<Combo[]>();
  const [yearsCombo, setYearsCombo] = useState<Combo[]>();

  const [vehicleBrandSelected, setVehicleBrandSelected] = useState<Combo>();
  const [vehicleYearSelected, setVehicleYearSelected] = useState<Combo>();
  const [vehicleModelSelected, setVehicleModelSelected] = useState<Combo>();
  const [vehicleVersionSelected, setVehicleVersionSelected] = useState<Combo>();

  const [haveYearsSelected, setHaveYearsSelected] = useState(false);

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,
    [sourcePartnerContext]
  );
  const isCarCategory = useMemo(
    () => vehicleCategoryContext === VehicleCategoriesEnum.Car,
    [vehicleCategoryContext]
  );

  const getVehiclesBrandAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllVehicleBrand(
      vehicleCategoryContext,
      sourcePartnerContext
    );
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );
    setVehicleBrandCombo(itemsCombo);
  }, [GetAllVehicleBrand, sourcePartnerContext, vehicleCategoryContext]);

  const getVehicleVersionAsync = useCallback(
    async (modelId: number, year: number): Promise<void> => {
      if (modelId && year) {
        const items = await GetAllVehicleVersion(
          modelId,
          year,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: isSourceKbb ? m.kbbid : m.molicarID,
            })
        );
        setVehicleVersionCombo(itemsCombo);
      }
    },
    [GetAllVehicleVersion, isSourceKbb, sourcePartnerContext]
  );

  const getVehiclesModelAsync = useCallback(
    async (brandId: number, year: number | null = null): Promise<void> => {
      if (brandId) {
        const items = await GetAllVehicleModelByBrand(
          brandId,
          year,
          vehicleCategoryContext,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: m.id,
            })
        );
        setVehicleModelCombo(itemsCombo);
      }
    },
    [GetAllVehicleModelByBrand, sourcePartnerContext, vehicleCategoryContext]
  );

  const GetAllYearsAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllYears(sourcePartnerContext);
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.year,
          value: m.year,
        })
    );
    const currentYear = new Date().getFullYear();
    if (props.isZeroKm) {
      setYearsCombo([
        new Combo({
          title: currentYear + 1,
          value: currentYear + 1,
        }),
        new Combo({
          title: currentYear,
          value: currentYear,
        }),
        new Combo({
          title: currentYear - 1,
          value: currentYear - 1,
        }),
      ]);
    } else {
      setYearsCombo(itemsCombo);
    }
  }, [GetAllYears, props.isZeroKm, sourcePartnerContext]);

  const getVehicleAsync = useCallback(
    async (
      id: number,
      molicarID: string | null,
      year: number
    ): Promise<void> => {
      const vehicle = await GetVehicle(id, molicarID, year);
      setKbbVehicleContext(vehicle);
    },
    [GetVehicle, setKbbVehicleContext]
  );

  useEffect(() => {
    if (isCarCategory) {
      setSourcePartnerContext(SourcePartnerEnum.Kbb);
    } else {
      setSourcePartnerContext(SourcePartnerEnum.Molicar);
    }
  }, [isCarCategory, setSourcePartnerContext, sourcePartnerContext]);

  useEffect(() => {
    if (kbbVehicleContext !== undefined && !vehicleVersionCombo) {
      if (isSourceKbb) {
        getVehicleAsync(
          Number(kbbVehicleContext.id),
          null,
          kbbVehicleContext.year
        );
      } else {
        getVehicleAsync(0, kbbVehicleContext.molicarID, kbbVehicleContext.year);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleVersionSelected, vehicleYearSelected?.value]);

  useEffect(() => {
    getVehiclesBrandAsync();
    GetAllYearsAsync();
  }, [vehicleCategoryContext, sourcePartnerContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kbbVehicleContext && vehicleBrandCombo) {
      const brand = vehicleBrandCombo?.find(
        (f) => f.value === kbbVehicleContext.brandID
      );
      setVehicleBrandSelected(brand);
    }
  }, [kbbVehicleContext, vehicleBrandCombo]);

  useEffect(() => {
    if (kbbVehicleContext && yearsCombo) {
      const year = yearsCombo?.find((f) => f.value === kbbVehicleContext?.year);
      setVehicleYearSelected(year);
    }
  }, [kbbVehicleContext, yearsCombo]);

  useEffect(() => {
    if (vehicleBrandSelected && vehicleYearSelected)
      getVehiclesModelAsync(
        Number(vehicleBrandSelected.value),
        Number(vehicleYearSelected.value)
      );
  }, [vehicleBrandSelected, vehicleYearSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kbbVehicleContext && vehicleModelCombo) {
      const model = vehicleModelCombo?.find(
        (f) => f.value === kbbVehicleContext.modelID
      );
      setVehicleModelSelected(model);
    }
  }, [kbbVehicleContext, vehicleModelCombo]);

  useEffect(() => {
    if (vehicleModelSelected && vehicleYearSelected) {
      getVehicleVersionAsync(
        Number(vehicleModelSelected.value),
        Number(vehicleYearSelected.value)
      );
    }
  }, [vehicleModelSelected, vehicleYearSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kbbVehicleContext && vehicleVersionCombo) {
      let version: Combo | undefined;
      if (isSourceKbb) {
        version = vehicleVersionCombo?.find(
          (f) => f.value === kbbVehicleContext.id
        );
      } else {
        version = vehicleVersionCombo?.find(
          (f) => f.value === kbbVehicleContext.molicarID
        );
      }

      setVehicleVersionSelected(version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext, vehicleVersionCombo]);

  useEffect(() => {
    setVehicleVersionSelected(undefined);
    setVehicleBrandSelected(undefined);
    setVehicleModelSelected(undefined);
    setVehicleYearSelected(undefined);
    setKbbVehicleContext(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kbbVehicleContext?.year === 0) {
      setHaveYearsSelected(false);
    } else {
      setHaveYearsSelected(true);
    }
  }, [kbbVehicleContext?.year, vehicleYearSelected]);

  useEffect(() => {
    setVehicleCategoryContext(VehicleCategoriesEnum.Car);
  });

  useEffect(() => {
    vehicleBrandSelected
      ? props.setHasSelectedCategory(true)
      : props.setHasSelectedCategory(false);
  }, [props, vehicleBrandSelected]);

  const propsVehicleSearchOptions = useMemo((): VehicleSearchOptionsProps => {
    return {
      Dropdowns: {
        showAll: true,
        haveYearsSelected: haveYearsSelected,
        Brand: {
          placeholder: t("Select") + "...",
          Options: vehicleBrandCombo,
          Value: vehicleBrandSelected,
          disabled: true,
        },
        Model: {
          placeholder: t("Select") + "...",
          Options: vehicleModelCombo,
          Value: vehicleModelSelected,
          disabled: true,
        },
        Version: {
          placeholder: t("Select") + "...",
          Options: vehicleVersionCombo,
          Value: vehicleVersionSelected,
          disabled: true,
        },
        Year: {
          placeholder: t("Select") + "...",
          Options: yearsCombo,
          Value: vehicleYearSelected,
          disabled: haveYearsSelected ? false : true,
          onChange: setVehicleYearSelected,
        },
      },
      Texts: {
        Brand: t("Brand"),
        ClearFilter: t("Clean Filters"),
        Loading: t("Loading"),
        Model: t("Model"),
        NoMatchesFound: t("NoMatchesFound"),
        Others: t("Others"),
        TitleYears: t("ModelYear"),
        Version: t("Version"),
      },
    };
    // eslint-disable-next-line
  }, [
    vehicleBrandCombo,
    yearsCombo,
    vehicleModelCombo,
    vehicleVersionCombo,
    vehicleBrandSelected,
    vehicleYearSelected,
    vehicleModelSelected,
    vehicleVersionSelected,
    t,
    haveYearsSelected,
  ]);

  return (
    <Row>
      <Col>
        <VehicleSearchOptions {...propsVehicleSearchOptions} />
      </Col>
    </Row>
  );
};
